@import '~@anwalt.de/design-system/dist/css/forms/search-form.css';

.anw-navbar-2021 {
  z-index: 1040;
}

.anw-search-form-mobile-bg {
  z-index: 3;
}

.anw-navbar-2021-toggler {
  &.collapsed {
    .anw-close {
      display: none;
    }
  }

  &:not(.collapsed) {
    border: none !important;

    .anw-magnifier {
      display: none;
    }

    .anw-close {
      font-size: 1.5rem;
    }
  }
}
