@import '../../../_defaults';

@import '~bootstrap/scss/utilities.scss';
@import '~bootstrap/scss/root';

@import '~@anwalt.de/design-system/scss/themes/anw-theme-v1';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-pro/scss/regular.scss';
@import '~@fortawesome/fontawesome-pro/scss/solid.scss';

.anw-navbar-tailwind {
  @import '~bootstrap/scss/reboot';
  @import '~bootstrap/scss/helpers';
  @import '~bootstrap/scss/utilities/api';
  @import '~bootstrap/scss/transitions';
  @import '~bootstrap/scss/bootstrap-grid';
  @import '~bootstrap/scss/offcanvas';
  @import '~@anwalt.de/design-system/scss/content/typography/typography';
  @import '~@anwalt.de/design-system/scss/components/buttons';
  @import '~@anwalt.de/design-system/scss/components/navbar-tabs';
  @import '~@anwalt.de/design-system/scss/components/navbar-2021';
  @import '~@anwalt.de/design-system/scss/forms/forms';
  @import '../../components/search-form/styles';

  .anw-nav-sidebar-container {
    z-index: 1000;
  }

  .anw-navbar-2021 {
    border-bottom: none !important;
    &.px-3 {
      @media (min-width: 1366px) {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
      }
    }
  }

  .anw-navbar-2021-toggler-search {
    margin-left: 6px;
    margin-right: 6px;
  }

  .anw-search-form-mobile-bg {
    z-index: 20;
  }

  @include media-breakpoint-down(sm) {
    .anw-offcanvas-new {
      .anw-highlight-nav-mobile {
        color: $primary !important;
        font-weight: $font-weight-bold !important;
      }
    }
  }
}

.anw-no-js {
  .anw-offcanvas-2021 {
    ul li a.anw-section-headline::after {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-toggler-icon {
      cursor: pointer;
    }
  }
}
